import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),

  fullName: yup.string().required(),

  dateOfBirth: yup.string().nullable(),

  emails: yup.array().of(yup.string()).required(),

  phones: yup.array().of(yup.string()).required(),

  pinCode: yup.string().nullable(),

  canAddCreditCard: yup.bool().required(),

  creditCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        cardNumber: yup.string().required(),
        billingZip: yup.string().nullable(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  const client = res?.data?.data || {};
  const cards = client?.card_on_files || [];
  return {
    id: client.id,

    fullName: client.full_name,

    dateOfBirth: client.date_of_birth || null,

    emails: [
      client.email,
      client.email_2,
      client.email_3,
      client.email_4,
    ].filter(Boolean),

    phones: [
      client.phoneNumber,
      client.phoneNumber_2,
      client.phoneNumber_3,
      client.phoneNumber_4,
    ].filter(Boolean),

    pinCode: client.pincode || null,

    canAddCreditCard: Boolean(client.can_add_card),

    creditCards: cards?.map((card) => ({
      id: card.id,
      cardNumber: card.card_number,
      billingZip: card.billing_zip || null,
    })),
    clinicId: client.clinic_id,
  };
};

function createClientQueryKey({ clientId }) {
  return [QUERY_KEYS.client, clientId];
}

export function useClientQuery(payload, options = {}) {
  return useQuery(
    createClientQueryKey({
      clientId: payload?.clientId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getClient(req.clientId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
